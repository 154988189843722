import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: #ffffff;
  padding: 0 20px;
`

const Child = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 50px;
  }

  & > *:not(:last-child) {
    max-width: 630px;
  }
  & > div > iframe {
    @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: 98vw;
    }
  }

  & > *:last-child {
    width: 100%;
  }
`

const PostContainer: React.FC = ({ children }) => (
  <Container>
    <Child>{children}</Child>
  </Container>
)

export default PostContainer
